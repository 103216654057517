
import { modalController, popoverController, loadingController } from "@ionic/vue";
import { defineComponent } from "vue";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import ProjectCss from "@/components/ListCard/ProjectCss.vue";
import { useRouter } from "vue-router";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import ProjectQueryCondition from "@/services/project/models/ProjectQueryCondition";
import { getInstance } from "@/services/project/projectService";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "Collect",
  components: {
    Header,
    SearchBar,
    TaskCards,
    ProjectCss,
    CardLoading,
  },
  data() {
    const projectService = getInstance();
    const params = this.$route.params;
    return {
      btnTextRight: ["iconbianjijieduan"],
      btnSizeRight: ["34px"],
      list: new Array(0),
      edit: false,
      projectService: projectService,
      projectParams: {
        limit: 10,
        offset: 1,
        projectName: "",
        partakeType: "myStar",
      },
      isMore: true,
      router: useRouter(),
      pageType: "",
      headerTitle: "我的收藏",
      islist: true,
      cancelId: "",
      starSwitch: false, //接口开关
      $refs: {
        SearchBarDom: SearchBar,
      },
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route() {
      this.list = [];
      this.initData();
    },
  },
  methods: {
    initData() {
      this.getParams();
    },
    getParams() {
      this.projectParams.offset = 1;
      this.requsetProjectData(this.projectParams, null);
    },
    //下拉刷新
    doRefresh(event: any) {
      this.isMore = true;
      this.projectParams.offset = 1;
      this.getParams();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //下一页数据
    nextPage(event: any) {
      if (this.isMore) {
        this.projectParams.offset++;
        this.requsetProjectData(this.projectParams, event);
      }
    },
    //执行搜索
    searchHandler(val: any) {
      if (Utils.isInCludeEmoji(val.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.isMore = true;
      this.projectParams.offset = 1;
      this.projectParams.projectName = val.searchKey;
      this.requsetProjectData(this.projectParams, null);
    },
    //数据请求接口
    requsetProjectData(pamas: any, event: any) {
      this.projectService.getProjectList(pamas).then(
        (r: any) => {
          if (r) {
            const result = r.data;

            //测试提出BUG 集合为空显示暂无数据 不应弹出提示 2020-01-11 dinglf
            if (result == null || result.length <= 0) {
              //   Utils.presentToastWarning("暂无数据，请换个搜索条件吧！");
              if (event) {
                event.target.disabled = true;
              } else {
                this.list = [];
              }
            }
            if (event != null) {
              this.list = this.list.concat(result);
            } else {
              this.list = result;
            }
            setTimeout(() => {
              if (event) {
                event.target.complete();
              }
            }, 500);
            // }
            this.islist = false;
          } else {
            if (event != null) {
              // this.isEmpty = true;
              this.list = [];
            }
            this.islist = false;
          }
        },
        (e: any) => {
          this.islist = false;
        }
      );
    },
    //跳转详情
    itemClick(item: any) {
      if (!this.edit) {
        this.router.push({
          path: "/ProjectPage",
          query: {
            projectId: item.id,
            projectName: item.name,
            isStart: item.isStart,
          },
        });
      }
    },
    onRightClick() {
      this.edit = !this.edit;
      let list_box = document.querySelector(".list_box") as HTMLCanvasElement;

      if (this.edit) {
        this.btnTextRight = ["iconduihao"];
        list_box.setAttribute("class", "list_box show");
      } else {
        this.btnTextRight = ["iconbianjijieduan"];
        list_box.setAttribute("class", "list_box hidden");
      }
    },
    //取消收藏
    cancel(id: string) {
      this.starSwitch = true;
      const params = {
        projectId: id,
        isStart: 1,
      };
      this.projectService.updateForProjectList(params).then(
        (r: any) => {
          if (r) {
            this.getParams();
            this.starSwitch = false;
          }
        },
        (e: any) => {
          Utils.presentToastTopWarning("收藏失败");
        }
      );
    },
  },
});
